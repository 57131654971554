<template>
  <b-row>
    <b-col cols="12" class="p-0">
      <b-card no-body>
        <b-card-body class="p-0">
          <iframe name="__tt_embed__v89701512389719150" :src="`https://www.tiktok.com/embed/v2/${id}?lang=es`" class="tiktok-iframe"></iframe>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol, BCard, BCardBody } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody
  },
  props: {
    id: {
      type: String,
      required: true
    },
    username: {
      type: String,
      required: true
    },
  },
  created () {
    const ckeditor = document.createElement('script');
    ckeditor.setAttribute('src',"https://www.tiktok.com/embed.js");
    document.head.appendChild(ckeditor);
  }
};
</script>
<style lang="scss" scoped>
.tiktok-iframe {
  width: 100%;
  height: 720px;
  display: block;
  visibility: unset;
  border: none;
}
</style>
